@import-normalize;
@import "bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
    --c-primary-light: #bf1f19;
    --c-primary: #a41814;
    --c-primary-dark: #6e100d;
    --c-secondary-light: #ffe65a;
    --c-secondary: #ffd900;
    --c-secondary-dark: #d9ad00;
    --c-white: #ffffff;
    --c-black: #000000;
}

html {
    height: 100%;
}

body {
    margin: 0;
    display: flex;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    background-color: var(--c-primary);
    background-image: url("../public/images/background.jpg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: var(--c-white);

    #root {
        display: flex;
        width: 100%;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Alfa Slab One", serif;
    font-weight: 400;
    font-style: normal;
    color: var(--c-secondary);
}

h1,
h2 {
    text-transform: uppercase;
}

h3 {
    color: var(--c-white);
    font-size: 1.5rem;
}

img {
    max-width: 100%;
    height: auto;
}

svg {
    * {
        fill: currentColor;
    }
}

.btn {
    font-family: "Alfa Slab One", serif;
    text-transform: uppercase;

    &.btn-yellow {
        --bs-btn-color: var(--c-black);
        --bs-btn-bg: var(--c-secondary);
        --bs-btn-hover-color: var(--c-black);
        --bs-btn-hover-bg: var(--c-secondary-dark);
        --bs-btn-font-size: 1.2rem;
    }
}

.anchor {
    margin-top: -110px;
    padding-top: 110px;
    @include media-breakpoint-up(md) {
        margin-top: -130px;
        padding-top: 130px;
    }
}
