@import "bootstrap/scss/bootstrap.scss";

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    @include media-breakpoint-up(md) {
        height: 100px;
    }
    z-index: 1000;
    background-color: transparent;
    transition: background-color 0.3s;
    padding: 1rem 0;
    pointer-events: none;

    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 30px;
        background: rgb(164, 24, 20);
        background: linear-gradient(0deg, rgba(164, 24, 20, 0) 0%, rgba(164, 24, 20, 1) 100%);
        z-index: 0;
        opacity: 0;
        transition: opacity 0.3s;
    }

    .header-logo {
        opacity: 0;
        width: 100%;
        height: auto;
        transition: opacity 0.3s;
        max-width: 160px;
        @include media-breakpoint-up(md) {
            max-w: 200px;
        }
    }

    .social-media a,
    .btn {
        pointer-events: all;
    }

    &.scroll {
        background-color: var(--c-primary);
        pointer-events: all;

        &:after {
            opacity: 1;
        }

        .header-logo {
            opacity: 1;
        }
    }
}
