@import "bootstrap/scss/bootstrap.scss";

.app {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    .main-container {
        margin-bottom: 3rem;
    }

    .logo {
        width: 100%;
        max-width: 960px;

        @include media-breakpoint-down(xxl) {
            margin-top: -5%;
        }

        @include media-breakpoint-down(sm) {
            margin-top: 30px;
        }
    }

    .social-link {
        display: inline-block;
        width: 32px;
        height: 32px;
        color: var(--c-secondary);
        transition: color 0.3s;

        &:hover {
            color: white;
        }
    }

    .attributions {
        font-size: 12px;
        opacity: 0.75;
    }
}
